.about {
  background-color: #262626;
  &__wrapper {
    padding: 10rem 15rem;
    @media screen and (max-width: 1600px) {
      padding: 10rem 4rem;
    }
    @include breakpoint-down(medium) {
      padding: 8rem 3rem;
    }
    @include breakpoint-down(small) {
      padding: 8rem 2rem;
    }
  }
  &__heading {
    text-align: center;
    margin-bottom: 10rem;
    font-size: 50px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    color: #fff;
    @include breakpoint-down(medium) {
      font-size: 40px;
    }
    @include breakpoint-down(small) {
      font-size: 36px;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-info {
      margin-right: 10rem;
      flex-basis: 45%;
      @media screen and (max-width: 1300px) {
        margin-right: 5rem;
      }
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-bottom: 5rem;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2rem;
        line-height: 30px;
        color: #fff;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
    }
    &-img {
      flex-basis: 50%;
      img {
        width: 100%;
      }
    }
  }
}
.contact {
  background-color: #406c83;
  padding: 10rem 35rem;
  @media screen and (max-width: 1600px) {
    padding: 10rem 5rem;
  }
  @include breakpoint-down(medium) {
    padding: 10rem 4rem;
  }
  @include breakpoint-down(small) {
    padding: 8rem 2rem;
  }
  &__heading {
    text-align: center;
    margin-bottom: 10rem;
    font-size: 50px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    color: #fff;
    @include breakpoint-down(medium) {
      font-size: 40px;
    }
    @include breakpoint-down(small) {
      font-size: 36px;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &-info {
      margin-left: 10rem;
      flex-basis: 45%;
      @media screen and (max-width: 1300px) {
        margin-left: 5rem;
        width: 100%;
      }
      @include breakpoint-down(medium) {
        margin-left: 0rem;
        margin-bottom: 5rem;
      }
      hr {
        width: 100%;
        margin: 3rem 10rem 3rem 0rem;
        @include breakpoint-down(small) {
          margin: 3rem auto;
        }
      }
      &-flexs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include breakpoint-down(small) {
          flex-direction: column;
          gap: 2rem;
        }
        p {
          font-size: 14px;
          color: #fff;
        }
        h4 {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: #fff;
          @include breakpoint-down(small) {
            font-size: 18px;
          }
        }
      }
    }
    &-img {
      flex-basis: 50%;
      img {
        width: 100%;
      }
    }
  }
}
