.platform {
  background-color: #262626;
  &__wrapper {
    padding: 10rem 15rem;
    @media screen and (max-width: 1600px) {
      padding: 10rem 4rem;
    }
    @include breakpoint-down(medium) {
      padding: 8rem 3rem;
    }
    @include breakpoint-down(small) {
      padding: 8rem 2rem;
    }
  }
  &__heading {
    margin-bottom: 10rem;
    h2 {
      text-align: center;
      margin-bottom: 4rem;
      font-size: 50px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 400;
      color: #fff;
      @include breakpoint-down(medium) {
        font-size: 40px;
      }
      @include breakpoint-down(small) {
        font-size: 36px;
      }
    }
    p {
      position: relative;

      text-align: center;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 2rem;
      color: #fff;
      line-height: 30px;

      @include breakpoint-down(medium) {
        font-size: 15px;
      }
      @include breakpoint-down(small) {
        width: 100%;
        line-height: 25px;
        font-size: 14px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-info {
      margin-right: 10rem;
      flex-basis: 45%;
      @media screen and (max-width: 1300px) {
        margin-right: 5rem;
      }
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-bottom: 5rem;
        text-align: center;
      }
      h2 {
        margin-bottom: 2rem;
        font-size: 38px;
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        color: #fff;
        @include breakpoint-down(medium) {
          font-size: 36px;
        }
        @include breakpoint-down(small) {
          font-size: 30px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2rem;
        line-height: 30px;
        color: #fff;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
      &-btn {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        @include breakpoint-down(medium) {
          justify-content: center;
        }
        button {
          padding: 1.5rem 6rem;
          font-size: 18px;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          border: none;
          @include breakpoint-down(small) {
            padding: 1rem 3rem;
            font-size: 16px;
          }
          &:first-child {
            margin-right: 2rem;
            background-color: #fff;
            color: #262626;
            border: 2px solid #fff;
            &:hover {
              background-color: transparent;
              color: #fff;
            }
          }
          &:last-child {
            background-color: #406c83;
            color: #fff;
            border: 2px solid #406c83;
            &:hover {
              background-color: transparent;
              color: #fff;
            }
          }
        }
      }
    }
    &-img {
      flex-basis: 50%;
      img {
        width: 100%;
      }
    }
  }
  &__info {
    margin-top: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &-img {
      flex-basis: 50%;
      margin-right: 10rem;
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-top: 5rem;
      }
      img {
        width: 100%;
      }
    }
    &-text {
      flex-basis: 50%;
      h2 {
        font-size: 28px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 2rem;
        @include breakpoint-down(medium) {
          text-align: center;
          font-size: 26px;
        }
        @include breakpoint-down(small) {
          font-size: 22px;
        }
      }
      ul {
        margin-left: 5rem;
        li::marker {
          font-size: 2rem;
        }
        li {
          font-size: 18px;
          font-family: "Open Sans", sans-serif;
          margin-bottom: 1rem;
          color: #fff;
          line-height: 25px;
          @include breakpoint-down(small) {
            font-size: 15px;
          }
        }
      }
    }
  }
}
.webTrader {
  &__wrapper {
    padding: 10rem 15rem;
    @media screen and (max-width: 1600px) {
      padding: 10rem 4rem;
    }
    @include breakpoint-down(medium) {
      padding: 8rem 3rem;
    }
    @include breakpoint-down(small) {
      padding: 8rem 2rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &-info {
      margin-left: 10rem;
      flex-basis: 45%;
      @media screen and (max-width: 1300px) {
        margin-left: 5rem;
      }
      @include breakpoint-down(medium) {
        margin-left: 0rem;
        margin-bottom: 5rem;
        text-align: center;
      }
      h2 {
        margin-bottom: 2rem;
        font-size: 38px;
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        color: #262626;
        @include breakpoint-down(medium) {
          font-size: 36px;
        }
        @include breakpoint-down(small) {
          font-size: 30px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2rem;
        line-height: 30px;
        color: #262626;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
      &-btn {
        display: inline-block;
        padding: 1.5rem 6rem;
        background-color: #406c83;
        color: #fff;
        border: 2px solid #406c83;
        font-size: 18px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        text-transform: uppercase;

        &:hover {
          background-color: transparent;
          color: #406c83;
        }
        @include breakpoint-down(small) {
          padding: 1rem 3rem;
          font-size: 16px;
        }
      }
    }
    &-img {
      flex-basis: 50%;
      img {
        width: 100%;
      }
    }
  }
  &__info {
    margin-top: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    &-text {
      flex-basis: 50%;
      &:first-child {
        margin-right: 10rem;
        @include breakpoint-down(medium) {
          margin-right: 0rem;
          margin-bottom: 5rem;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2rem;
        line-height: 30px;
        color: #262626;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
      h2 {
        font-size: 28px;
        font-weight: 400;
        color: #262626;
        margin-bottom: 2rem;
        @include breakpoint-down(medium) {
          text-align: center;
          font-size: 26px;
        }
        @include breakpoint-down(small) {
          font-size: 22px;
        }
      }
      ul {
        margin-left: 5rem;
        li::marker {
          font-size: 2rem;
        }
        li {
          font-size: 18px;
          font-family: "Open Sans", sans-serif;
          margin-bottom: 1rem;
          color: #262626;
          line-height: 25px;
          @include breakpoint-down(small) {
            font-size: 15px;
          }
        }
      }
    }
  }
}
.mobileTrader {
  background-color: #262626;
  &__wrapper {
    padding: 10rem 15rem;
    @media screen and (max-width: 1600px) {
      padding: 10rem 4rem;
    }
    @include breakpoint-down(medium) {
      padding: 8rem 3rem;
    }
    @include breakpoint-down(small) {
      padding: 8rem 2rem;
    }
  }
  &__heading {
    margin-bottom: 10rem;
    h2 {
      text-align: center;
      margin-bottom: 4rem;
      font-size: 50px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 400;
      color: #fff;
      @include breakpoint-down(medium) {
        font-size: 40px;
      }
      @include breakpoint-down(small) {
        font-size: 36px;
      }
    }
    p {
      position: relative;

      text-align: center;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 2rem;
      color: #fff;
      line-height: 30px;

      @include breakpoint-down(medium) {
        font-size: 15px;
      }
      @include breakpoint-down(small) {
        width: 100%;
        line-height: 25px;
        font-size: 14px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-info {
      margin-right: 10rem;
      flex-basis: 45%;
      @media screen and (max-width: 1300px) {
        margin-right: 5rem;
      }
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-bottom: 5rem;
        text-align: center;
      }
      h2 {
        margin-bottom: 2rem;
        font-size: 38px;
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        color: #fff;
        @include breakpoint-down(medium) {
          font-size: 36px;
        }
        @include breakpoint-down(small) {
          font-size: 30px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 2rem;
        line-height: 30px;
        color: #fff;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
      &-btn {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        @include breakpoint-down(medium) {
          justify-content: center;
        }
        button {
          padding: 1.5rem 6rem;
          font-size: 18px;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          border: none;
          @include breakpoint-down(small) {
            padding: 1rem 3rem;
            font-size: 16px;
          }
          &:first-child {
            margin-right: 2rem;
            background-color: #fff;
            color: #262626;
            border: 2px solid #fff;
            &:hover {
              background-color: transparent;
              color: #fff;
            }
          }
          &:last-child {
            background-color: #406c83;
            color: #fff;
            border: 2px solid #406c83;
            &:hover {
              background-color: transparent;
              color: #fff;
            }
          }
        }
      }
    }
    &-img {
      flex-basis: 50%;
      img {
        width: 100%;
      }
    }
  }
  &__info {
    margin-top: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &-img {
      flex-basis: 50%;
      margin-right: 10rem;
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-top: 5rem;
      }
      img {
        width: 100%;
      }
    }
    &-text {
      flex-basis: 50%;
      h2 {
        font-size: 28px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 2rem;
        @include breakpoint-down(medium) {
          text-align: center;
          font-size: 26px;
        }
        @include breakpoint-down(small) {
          font-size: 22px;
        }
      }
      ul {
        margin-left: 5rem;
        li::marker {
          font-size: 2rem;
        }
        li {
          font-size: 18px;
          font-family: "Open Sans", sans-serif;
          margin-bottom: 1rem;
          color: #fff;
          line-height: 25px;
          @include breakpoint-down(small) {
            font-size: 15px;
          }
        }
      }
    }
  }
  &__text {
    margin-top: 10rem;
    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 2rem;
      line-height: 30px;
      color: #fff;
      text-align: center;
      @include breakpoint-down(small) {
        font-size: 14px;
      }
    }
  }
}
