@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Ubuntu:wght@400;500;700&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  position: relative;
  font-family: "Open Sans", "Ubuntu", sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  line-height: normal;
}
p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  line-height: normal;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

button {
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
