.footer {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../assets/footerBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.4;
  }
  padding: 10rem 15rem;
  @media screen and (max-width: 1600px) {
    padding: 10rem 5rem;
  }
  @include breakpoint-down(medium) {
    padding: 10rem 4rem;
  }
  @include breakpoint-down(small) {
    padding: 10rem 2rem;
  }
  &__content {
    position: relative;
    z-index: 10;

    &-risk {
      @include breakpoint-down(medium) {
        text-align: center;
      }
      h2 {
        font-size: 2.5rem;
        color: #fff;
        font-weight: 400;
        font-family: "Ubuntu", sans-serif;
        margin-bottom: 4rem;
        @include breakpoint-down(small) {
          font-size: 1.8rem;
        }
      }
      p {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.03em;
        margin-bottom: 2rem;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
    }
    &-links {
      flex-basis: 50%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 5rem;
      justify-items: center;
      align-self: center;
      margin-bottom: 10rem;
      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include breakpoint-down(medium) {
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
      }
      @include breakpoint-down(small) {
        grid-template-columns: repeat(1, 1fr);
      }
      &-single {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        @include breakpoint-down(medium) {
          margin-right: 3rem;
        }
        @include breakpoint-down(small) {
          margin-right: 0rem;
          flex-direction: column;
        }
        h3 {
          font-family: "Ubuntu", sans-serif;
          font-weight: 500;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0.03em;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 1rem;

          @include breakpoint-down(small) {
            font-size: 18px;
          }
        }
        a {
          font-family: "Open Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.03em;
          color: #fff;
          @include breakpoint-down(medium) {
            font-size: 15px;
          }
          @include breakpoint-down(small) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    text-align: center;
    background-color: transparent;

    h4 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
    }
  }
}
