.wrapper {
  background: #737373;
  position: relative;
  width: 100%;
  z-index: 1000;
}
.wrapper nav {
  position: sticky;
  display: flex;
  max-width: calc(100% - 300px);
  margin: 0 auto;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}

nav .content {
  display: flex;
  align-items: center;
}
nav .content .links {
  margin-left: 80px;
  display: flex;
  align-items: center;
}

.content .links li {
  list-style: none;
  line-height: 70px;
}
.content .links li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  padding: 9px 17px;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  transition: all 0.3s ease;
}
.header__oa-btn {
  border: 3px solid #B08C0B;
  color: #fff;
  text-transform: uppercase;
  background: #B08C0B;
  font-family: "Open Sans", sans-serif;
  padding: 1rem 3rem 1rem;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.header__oa-btn:hover {
  background: transparent;
  color: #fff;
}
.header__lg-btn {
  color: #0c1d40;
  background: #d9d9d9;
  border: 2px solid #d9d9d9;

  text-transform: uppercase;
  padding: 1rem 3rem 1rem;
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
.header__lg-btn:hover {
  background: transparent;
  color: #fff;
}
.content .links li label {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 17px;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}
.content .links li label {
  display: none;
}

.wrapper .menu-icon {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  line-height: 70px;
  width: 70px;
  text-align: center;
}
.wrapper .menu-icon {
  display: none;
}

.wrapper input[type="checkbox"] {
  display: none;
}

/* Dropdown Menu code start */
.content .links ul {
  position: absolute;
  background: #406c83;
  top: 100px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.content .links li:hover > ul {
  top: 80px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
.content .links ul li a {
  display: block;
  color: #fff;
  width: 100%;
  line-height: 30px;
  border-radius: 0px !important;
}
.content .links ul ul {
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content .links ul li {
  position: relative;
}
.content .links ul li:hover ul {
  top: 0;
}

/* Responsive code start */
@media screen and (max-width: 1650px) {
  .wrapper nav {
    max-width: calc(100% - 100px);
  }
}
@media screen and (max-width: 650px) {
  .wrapper nav {
    max-width: calc(100% - 50px);
  }
}
@media screen and (max-width: 1450px) {
  .wrapper nav {
    max-width: 100%;
    padding: 0 20px;
  }
  nav .content .links {
    margin-left: 30px;
  }
  .content .links li a {
    padding: 8px 13px;
  }

  .wrapper .search-box input {
    padding: 0 100px 0 15px;
  }
}

@media screen and (max-width: 1450px) {
  .wrapper nav {
    flex-direction: row-reverse;
  }
  nav .content {
    position: absolute;
  }
  nav .logo a img {
    width: 80%;
  }
  .wrapper .menu-icon {
    display: block;
  }
  .wrapper #show-menu:checked ~ .menu-icon i::before {
    content: "\f00d";
  }

  nav .content .links {
    display: block;
    position: fixed;
    background: #737373;
    height: 100%;
    width: 100%;
    top: 100px;
    right: -100%;
    margin-left: 0;
    max-width: 350px;
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.3s ease;
  }

  nav #show-menu:checked ~ .content .links {
    right: 0%;
  }
  .content .links li {
    margin: 15px 20px;
  }
  .content .links li a,
  .content .links li label {
    line-height: 40px;
    font-size: 20px;
    display: block;
    padding: 8px 18px;
    cursor: pointer;
  }
  .content .links li a.desktop-link {
    display: none;
  }

  /* dropdown responsive code start */
  .content .links ul,
  .content .links ul ul {
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }
  .content .links #show-about:checked ~ ul,
  .content .links #show-accounts:checked ~ ul,
  .content .links #show-platform:checked ~ ul,
  .content .links #show-tools:checked ~ ul,
  .content .links #show-assets:checked ~ ul,
  .content .links #show-resources:checked ~ ul {
    max-height: 100vh;
  }
  .content .links ul li {
    margin: 7px 20px;
  }
  .content .links ul li a {
    font-size: 18px;
    line-height: 30px;
    border-radius: 5px !important;
  }
}

@media screen and (max-width: 400px) {
  .wrapper nav {
    padding: 0 10px;
  }
  nav .logo a img {
    width: 70%;
  }
  .content .logo a {
    font-size: 27px;
  }
}
